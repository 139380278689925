<template>
  <div class="flex-grow-1">
    <admin-title title="Invitations">
      <template v-slot:actions>
        <v-btn
          @click="getInvites()"
          :loading="status.getting"
          icon
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
    </admin-title>

    <div>
      <preloader v-if="status.getting" />

      <custom-alert v-if="!status.getting && !invites.length">
        You don't have any invitations.
      </custom-alert>

      <v-card v-if="invites.length && !status.getting" outlined>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Job Title</th>
                <th>School Name</th>
                <th>Start Date</th>
                <th>Status</th>
                <th>Date Invited</th>
                <th width="160"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="invite in invites"
                :key="invite.id"
              >
                <td>{{ $store.getters['jobs/jobData'](invite.jobId).title }}</td>
                <td>{{ $store.getters['schools/userSchool']($store.getters['jobs/jobData'](invite.jobId).useruid).name }}</td>
                <td>{{ $startDate($store.getters['jobs/jobData'](invite.jobId).contractDates) | formatDate }}</td>
                <td>{{ $store.getters['jobs/jobData'](invite.jobId).status }}</td>
                <td class="caption">{{ invite.createdAt | formatDate }}</td>
                <td class="text-right">
                  <v-btn 
                    :to="{ name: 'JobProfile', params: { id: invite.jobId } }" 
                    :disabled="$store.getters['jobs/jobData'](invite.jobId).status !== 'Open'"
                    color="primary"
                    class="mr-2"
                    depressed
                    small 
                  >View Job</v-btn>

                  <v-btn @click="showDeleteDialog(invite)" icon small>
                    <v-icon color="error" small>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :show="deleteDialog"
      :deleting="status.deleting"
      @cancel="hideDeleteDialog()"
      @confirmed="deleteConfirmed()"
      message="Are you sure you want to delete the job invitation?"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  metaInfo: {
    title: 'Invitations'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      deleteDialog: false,
      toDelete: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      invites: (state) => state.invite.myInvites,
      status: (state) => state.invite.status,
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('invite', [
      'getInvites',
      'deleteInvitation'
    ]),

    showDeleteDialog(invite) {
      this.toDelete = invite
      this.deleteDialog = true
    },

    hideDeleteDialog() {
      this.toDelete = null
      this.deleteDialog = false
    },

    deleteConfirmed() {
      Promise.all([this.deleteInvitation(this.toDelete)])
      .then(() => this.hideDeleteDialog())
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getInvites()
  }
}
</script>